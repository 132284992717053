// 微信展码链接参数
export const WX_PARAMS = {
  appid: 'wx681b4630b4ade9af',
  redirectUri: encodeURIComponent('https://webauth.wecity.qq.com'),
  scope: "snsapi_login",
  styleHref: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O2JvcmRlcjogbm9uZTt9Ci5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9Ci5pbXBvd2VyQm94IC5pbmZvIHtkaXNwbGF5OiBub25lO30='
  /**
   * 原始内容
.impowerBox .qrcode {width: 200px;border: none;}
.impowerBox .title {display: none;}
.impowerBox .info {display: none;}
   */
};

// QQ登录链接参数
export const QQ_PARAMS = {
  appid: '100556005',
  redirectUri: encodeURIComponent('http://www.corho.com:8080/#/login/callback')
};
