<template>
  <div class="phone-login">
    <div class="login-body">
      <form id="signup-form">
        <div class="phone-num pr">
          <div class="input">
            <img class="input-prefix" slot="item-icon" src="~assets/img/mobile.svg" alt="" />
            <input
              type="text"
              maxlength="11"
              name="phone"
              class="ipt ipt-phone"
              placeholder="请输入手机号"
              autocomplete="off"
              @blur="phoneTipShow()"
              v-model="form.phoneNum"
            />
            <img class="input-suffix" src="~assets/img/close-circle-filled.svg" @click="clear('phoneNum')" />
          </div>
        </div>
        <div class="err-tip">
          <p v-if="phoneError">请输入正确手机号</p>
        </div>
        <div class="phone-verify pr">
          <div class="input  ipt-pwd">
            <input
              type="text "
              maxlength="6"
              class="ipt"
              placeholder="验证码"
              autocomplete="off"
              @blur="codeTipShow()"
              v-model="form.smsCode"
            />
            <img class="input-suffix" src="~assets/img/close-circle-filled.svg" @click="clear('smsCode')" />
          </div>
          <div
            class="sms-code button"
            :class="{ disabled: isDisabled || phoneError }"
            @click="handlerCode"
            id="TencentCaptcha"
            data-appid="2004797314"
            data-cbfn="callback"
          >
            {{ codeText }}
          </div>
        </div>
        <div class="err-tip">
            <p v-if="smsCodeError">{{smsCodeErrorText}}</p>
          </div>
      </form>
      <input
        type="button"
        value="登录"
        hidefocus="true"
        class="button login-btn"
        :class="{disabled: loginButtonDisabled}"
        @click="handlerLogin"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhoneLogin',
  data() {
    return {
      phoneError: false,
      smsCodeError: false,
      smsCodeErrorText: '',
      isDisabled: false,
      codeText: '获取验证码',
      timer: null,
      form: {
        phoneNum: '',
        smsCode: ''
      },
    };
  },
  computed: {
    loginButtonDisabled() {
      return !(this.checkPhone() && this.checkSmsCode());
    }
  },
  methods: {
    checkPhone() {
      return this.form.phoneNum.length === 11;
    },
    checkSmsCode() {
      return this.form.smsCode.length === 6;
    },
    // 手机号错误提示
    phoneTipShow() {
      this.phoneError = !this.checkPhone();
    },
    // 验证码错误提示
    codeTipShow() {
      this.smsCodeError = !this.checkSmsCode();
      this.smsCodeErrorText = '验证码长度错误';
    },
    clear(name) {
      this.form[name] = '';
    },
    // 获取短信验证码
    handlerCode() {
      this.phoneTipShow();
      if (this.checkPhone()) {
        // 滑块验证
        const captcha = new window.TencentCaptcha('2004797314', res => {
          const that = this;
          if (res.ret === 0) {
            that.countDown();
            that.$api("/api/verify_code", {
              request: {
                verify_code_req: {
                  phone_number: this.form.phoneNum,
                  ticket: res.ticket,
                  randstr: res.randstr
                }
              }
            });
          } else {
            // 用户主动关闭验证码
            if (res.ret === 2) {
              that.$toast.showMessage('未完成滑块验证', 'warn');
              return;
            } else {
              alert("sth wrong");
            }
          }
        });
        captcha.show();
      }
    },
    // 倒计时函数
    countDown() {
      let second = 60;
      this.codeText = `${second}秒 后可重发`; // 初始化文本
      this.isDisabled = true; // 禁用按钮
      // 倒计时
      this.timer = setInterval(() => {
        second -= 1; // 累减
        this.codeText = `${second}秒 后可重发`; // 更新文本
        if (second === 0) {
          // 倒计时为0时，清除定时器
          this.codeText = `重新获取`;
          this.isDisabled = false; // 激活按钮
          // 清除定时器
          clearInterval(this.timer);
        }
      }, 1000);
    },
    // 手机登录
    async handlerLogin() {
      if (this.checkPhone() && this.checkSmsCode()) {
        try {
          const res = await this.$api("/api/login", {
            request: {
              login_req: {
                type: 'phone',
                code: this.form.smsCode,
                phone_number: this.form.phoneNum,
                service: this.$route.query.service,
                redirect_url: this.$route.query.redirect_url
              }
            },
          }, {}, {
            checkResponseData: (res) => new Promise((resovle) => resovle(res))
          });
          console.log(res);
          if (res.code === 0) {
            this.$toast.showMessage('登录成功！', 'success');
            window.location.replace(res.redirectUrl);
          } else if (res.code === 106) {
            this.smsCodeError = true;
            this.smsCodeErrorText = '验证码输入错误';
          } else {
            this.$toast.showMessage(`${res.message}`, 'error', 3000);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  }
};
</script>

<style  scoped>
.phone-login {
  height: 260px;
}

.login-header h1 {
  text-align: center;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  font-weight: 700;
  color: #787d82;
}

.login-header h1 span {
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

.login-body {
  padding: 0 32px;
  margin-bottom: 20px;
}

.input {
  position: relative;
}

.input-prefix {
  position: absolute;
  left: 16px;
  top: 11px;
  height: 16px;
}

.input:hover .input-suffix {
  display: block;
}

.input-suffix {
  position: absolute;
  right: 16px;
  top: 11px;
  height: 16px;
  cursor: pointer;
  display: none;
}

#signup-form {
  margin-top: 20px;
}
.ipt {
  width: 100%;
  height: 40px;
  line-height: 222px;
  box-sizing: border-box;
  padding: 9px;
  background: #F2F3F8;
  border-radius: 2px;
  border: none;
  outline: none;
  font-size: 14px;
}

.ipt::placeholder {
  color: rgba(152, 162, 178, 0.9);
}

.ipt-phone {
  padding-left: 36px;
  width: 366px;
}

.phone-verify {
  display: flex;
  align-items: center;
}

.ipt-pwd {
  flex: 1;
}

.sms-code {
  margin-left: 12px;
}

.button {
  padding: 12px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: #0077FF;
  color: white;
  cursor: pointer;
}

.button.disabled {
   background: #DCDCDC;
  cursor: not-allowed;
}

.login-btn {
  width: 100%;
  margin-top: 16px;
  border: none;
}

.err-tip {
  min-height: 24px;
  height: auto;
}

.err-tip p {
  height: 42px;
  font-size: 14px;
  line-height: 22px;
  color: #E34D59;
}
</style>
