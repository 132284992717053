<template>
  <div class="login-wrap">
    <div class="login-content">
      <div class="background-img" :style="backgroundImageStyle" ></div>

      <div class="login-content-right">
        <img  class="logo-img" src="~assets/img/th-logo.png" />
        <div class="title">{{ config.title }}</div>

        <div class="login-box">
          <div class="tab-box">
            <div class="tab-item" :class="isWxLogin ? 'active' : ''" @click="chooseWxLog(true)">扫码登录</div>
            <div class="tab-item" :class="isWxLogin ? '' : 'active'" @click="chooseWxLog(false)">账号登录</div>
          </div>
          <!-- 微信扫码登录 -->
          <wx-qrcode v-if="isWxLogin" />
          <!-- 手机验证码登录 -->
          <phone-login v-else  />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import PhoneLogin from './PhoneLogin.vue';
import WxQrcode from './WxQrcode.vue';

export default {
  name: 'Login',
  components: { PhoneLogin, WxQrcode },
  data() {
    return {
      isWxLogin: true,
      config: {},
    };
  },
  async created() {
    this.getInitConfig();
    // 带上参数appid
    if (this.$route.query.appId === undefined) {
      const { service } = this.$route.query;
      const { appId }  = await this.$api('/api/get_service_info', { serviceName: service });
      this.$router.replace({query: {
        ...this.$route.query,
        appId: appId
      }});
      window.location.replace(window.location.href);
    } else if (this.$route.query.appId !== undefined && this.$route.query.code === '10014') {  // 微信登录但未绑定手机号
      alert('您未绑定手机号，请使用手机验证码重新进行登录！');
      this.isWxLogin = false;
    }
  },
  computed: {
    backgroundImageStyle() {
      const { backgroundUrl } = this.config;
      return backgroundUrl ? `background-image: url("${backgroundUrl}")` : '';
    }
  },
  methods: {
    chooseWxLog(type) {
      this.isWxLogin = type;
    },
    backLogin(type) {
      this.isPhoneLogin = type;
    },
    async getInitConfig() {
      const { redirect_url, service } = this.$route.query;
      const url = decodeURIComponent(redirect_url);
      const res = await this.$api('https://wechat.wecity.qq.com/trpcapi/GuidePreCommonService/GetCommonConfig', {
        context: {},
        request: {
          keyword: 'loginPageConfig'
        },
        header: {
          'TH-Auth-Type': 'tencent-health-h5'
        }
      });
      const allConfig = JSON.parse(res.configValue);
      const config =  allConfig.find(item => {
        if (item.service === service && url.indexOf(item.path) !== -1) {
          return true;
        }
      });
      this.config = config;
      window.document.title = this.config.title;
    }

  }
};
</script>


<style  scoped>
.login-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #1C2028;
  overflow-y: auto;
}

.login-content {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 522px;
}

@media only screen and (max-width: 1024px) {
  .background-img {
    display: none;
  }
}


.background-img {
  flex: 7;
  background: white;
  background-size: cover;

}

.login-content-right {
  flex: 5;
  min-width: 416px;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-img {
  height: 40px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin-top: 8px;
}

.login-box {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab-box {
  width: 220px;
  display: flex;
  justify-content: space-between;
}

.tab-item {
  font-size: 20px;
  line-height: 28px;
  padding-bottom: 8px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.tab-item.active {
  color: #0077FF;
  border-color: #0077FF;

}
</style>
